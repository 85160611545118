<template>
	<div class="main_wrap _ma">
		<h2 class="section_title">
			<router-link
				to="/about"
			>
				소개
			</router-link>
			<span class="gutter">|</span>
			<a class="_curr">오시는 길</a>
		</h2>
		<div class="con_about">
			<section class="_map">
                <div class="sec_wrap" id="mapArea">
                    <div class="article_area">
                        <h5>
                            <span class="hi_bible">Hi Bible</span>
                            <span class="text">오시는 길</span>
                        </h5>
                        <div class="map_img">
                            <img :src="$store.state.BASE_URL+'resource/images/kor/ab_map.svg'" alt="3호선 옥수역 7번 출구에서 도보로 5분. 서울시 성동구 한림말3길14. 4층 하이바이블" />
                        </div>
                    </div>
                </div>
            </section>
		</div>
	</div>
</template>

<script>
import langCommon from '@/language/common'
import buildMeta from '@/plugins/buildMeta'

export default {
	name:"Map",
	metaInfo(){
		let lang = this.$store.state.lang;
		return buildMeta({
			target: this,
			isoCode: langCommon.isoCode[lang],
			title: langCommon.hibible[lang] + "-오시는길",
			desc: '사무실은 서울시 성동구 한림말3길 14, 4층이며 옥수서재로 오셔도 됩니다. 3호선 옥수역 7번출구에서 나와 도보로 2분거리입니다.',
			keyword: '하이바이블 오시는길, 서울시 성동구 한림말3길 14, 4층, 옥수서재, 3호선 옥수역 7번출구',
			thumb: '/hibible/resource/images/ab_map.svg',
		});
	},
}
</script>

